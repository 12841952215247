import * as Sentry from "@sentry/remix";
import { useEffect, useRef, useState } from "react";
import { translate } from "~/i18n";
import { Language } from "~/types";
import { loadScript } from "~/utils";
import styles from "./bookingWidget.module.css";
import Container from "./container";

interface Props {
  language: Language;
  propertyId: number;
}

export default function BookingWidget({ propertyId, language }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const widgetInstanceRef = useRef<Widgets.Instance>();
  const isScriptAddedRef = useRef(false);
  const [isError, setIsError] = useState(false);
  const trans = translate(language);

  // TODO: Fix widget not showing if this component is unmounted and then
  // mounted again. This doesn’t happen under normal circumstances.
  useEffect(() => {
    // Prevent the widget from creating a tracking cookie.
    window.BookingSüdtirolTrackingConsent = false;

    const loadWidgetScript = async () => {
      if (isScriptAddedRef.current) return;
      try {
        // Prevents the script from loading multiple times.
        isScriptAddedRef.current = true;
        await loadScript("https://widget.bookingsuedtirol.com/v2/bundle.js");
      } catch (error) {
        setIsError(true);
        console.error(error);
        Sentry.captureException(error);
      }
    };

    const loadWidget = async () => {
      if (!ref.current) {
        return;
      }

      await loadWidgetScript();

      const widget = window.BookingSüdtirol?.Widgets?.Booking;
      if (!widget) return;

      widgetInstanceRef.current?.unmount();
      widgetInstanceRef.current = widget(ref.current, {
        id: "9c16ef79-4189-4448-ab3d-79f45de21a28",
        propertyId,
        layout: "one_pager",
        colorScheme: {
          main: {
            background: "#ffffff00",
            text: undefined,
            separator: undefined,
            title: "#0062ae"
          },
          primary: {
            background: "#0062ae",
            text: "#fff"
          },
          secondary: {
            background: "#c2e1fa",
            text: "#0a4a80"
          }
        }
      });
    };

    loadWidget();
  }, [language, propertyId]);

  useEffect(() => {
    return () => {
      widgetInstanceRef.current?.unmount();
    };
  }, []);

  return (
    <Container>
      {isError && <p>{trans.errorLoadingWidget}</p>}
      <noscript>{trans.enableJavaScript}</noscript>
      <div className={styles.bookingWidget} ref={ref} />
    </Container>);

}