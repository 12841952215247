import { Link } from "@remix-run/react";
import { useRef } from "react";
import { translate } from "~/i18n";
import { APIProperty, Language, languages } from "~/types";
import Container from "./container";
import styles from "./footer.module.css";
import Imprint from "./imprint";
import Popup from "./popup";
import Privacy from "./privacy";

interface Props {
  language: Language;
  isBookingLinkCampaign: boolean;
  property: APIProperty;
  alternateURLs: { [l in Language]: string };
}

export default function Footer({
  language,
  isBookingLinkCampaign,
  property,
  alternateURLs
}: Props) {
  const trans = translate(language);
  const imprintRef = useRef<HTMLDialogElement>(null);
  const privacyRef = useRef<HTMLDialogElement>(null);

  return (
    <>
      <footer className={styles.container}>
        <Container>
          <nav>
            <ul className={styles.languageSelect}>
              {languages.map((language) =>
              <li key={language}>
                  <Link
                  className={styles.languageLink}
                  to={alternateURLs[language]}
                  rel="alternate"
                  hrefLang={language}>

                    {language}
                  </Link>
                </li>
              )}
            </ul>
          </nav>
          <a
            className={styles.item}
            href="#imprint"
            onClick={() => imprintRef.current?.showModal()}>

            {trans.imprint}
          </a>{" "}
          |{" "}
          <a
            className={styles.item}
            href="#privacy"
            onClick={() => privacyRef.current?.showModal()}>

            {trans.privacy}
          </a>
        </Container>
      </footer>

      <Popup dialogRef={imprintRef}>
        <Imprint language={language} />
      </Popup>

      <Popup dialogRef={privacyRef}>
        <Privacy
          language={language}
          onClose={() => privacyRef.current?.close()}
          property={property}
          isBookingLinkCampaign={isBookingLinkCampaign} />

      </Popup>
    </>);

}