import { Language } from "~/types";

interface Props {
  language: Language;
}

export default function Imprint({ language }: Props) {
  return getTranslations(language);
}

const getTranslations = (language: Language): JSX.Element => {
  const translations = {
    de: (
      <section>
        <h2>Impressum</h2>
        <address>
          <strong>Hoteliers- und Gastwirteverband</strong>
          <br />
          <strong>HGV Service Genossenschaft</strong>
          <br />
          Schlachthofstraße 59
          <br />
          I-39100 Bozen
        </address>
        <p>
          <a href="tel:+390471317700">Tel. +39 0471 317 700</a>
          <br />
          Fax +39 0471 317 701
          <br />
          <a href="mailto:info@bookingsuedtirol.com">
            info@bookingsuedtirol.com
          </a>
        </p>
        <p>Mwst.-Nr.: 00576540215</p>

        <h3>Copyright Information</h3>
        <h4>Icons</h4>
        <p>
          <a href="https://fonts.google.com/icons">Material Symbols</a>
        </p>

        <h3>Inhalte</h3>
        <p>
          Die auf dieser Seite angegebenen Informationen erfüllen den Zweck
          einer Erstinformation, die weder für den User noch für die HGV Service
          Genossenschaft verpflichtend sind. Für eine verbindliche Auskunft
          stehen Ihnen die jeweiligen Anbieter zur Verfügung. Alle Inhalte der
          Internetseite werden sorgfältig erarbeitet und in regelmäßigen
          Abständen aktualisiert. Gleichwohl können Fehler (bsp. technische
          Störungen, Verfälschungen durch unbefugte Dritte) auftreten. Die HGV
          Service Genossenschaft übernimmt daher keine Gewähr für die
          Richtigkeit, Vollständigkeit und Aktualität der zur Verfügung
          gestellten Inhalte der jeweiligen Anbieter.
        </p>

        <h3>Urheberrecht</h3>
        <p>
          Die auf dieser Website veröffentlichten Texte, Bilder, Fotos und
          anderen Inhalte sind urheberrechtlich geschützt und dürfen ohne
          vorherige Zustimmung weder ganz noch teilweise vervielfältigt,
          weitergegeben, verbreitet oder gespeichert werden.
        </p>

        <h4>
          Pflichtinformation nach EU-Verordnung Nr. 524/2013 des Europäischen
          Parlaments und Rats
        </h4>
        <p>
          Plattform zur Online-Beilegung verbraucherrechtlicher Streitigkeiten
          (ODR) der Europäischen Kommission:{" "}
          <a href="https://ec.europa.eu/consumers/odr/">
            https://ec.europa.eu/consumers/odr/
          </a>
        </p>
      </section>
    ),
    it: (
      <section>
        <h2>Crediti</h2>

        <address>
          <strong>Unione Albergatori e Pubblici Esercenti</strong>
          <br />
          <strong>HGV Service Cooperativa</strong>
          <br />
          Via Macello 59
          <br />
          I-39100 Bolzano
        </address>

        <p>
          Tel. <a href="tel:+390471317700">+39 0471 317 700</a>
          <br />
          Fax +39 0471 317 701
          <br />
          <a href="mailto:info@bookingsuedtirol.com">
            info@bookingsuedtirol.com
          </a>
        </p>
        <p>P.iva: 00576540215</p>

        <h3>Informazione Copyright</h3>
        <h4>Icone</h4>
        <p>
          <a href="https://fonts.google.com/icons">Material Symbols</a>
        </p>

        <h3>Contenuti</h3>
        <p>
          Le informazioni contenute in questa pagina sono fornite esclusivamente
          a scopo informativo e non costituiscono alcun impegno né da parte
          dell’utente né da parte dell’HGV Service Cooperativa. Per una
          consulenza vincolante vi invitiamo a rivolgervi ai singoli operatori
          del settore. Tutti i contenuti del sito web sono elaborati
          accuratamente e aggiornati regolarmente. Ciononostante possono
          verificarsi degli errori, ad esempio problemi di natura tecnica o
          informazioni false fornite da soggetti terzi non autorizzati. HGV
          Service Cooperativa declina quindi ogni responsabilità riguardo alla
          correttezza, completezza e attualità dei contenuti forniti dai singoli
          operatori.
        </p>

        <h3>Diritto d’autore </h3>
        <p>
          I testi, le immagini, le foto e gli altri contenuti riportati su
          questo sito web sono protetti dal diritto d’autore e non possono
          essere riprodotti, salvati, distribuiti e/o trasmessi a terzi,
          completamente o in parte, senza previo consenso.
        </p>

        <h4>
          Informazione obbligatoria ai sensi del Regolamento UE n. 524/2013 del
          Parlamento europeo e del Consiglio
        </h4>
        <p>
          Piattaforma per la risoluzione delle controversie online dei
          consumatori (ODR) della Commissione europea:{" "}
          <a href="https://ec.europa.eu/consumers/odr/">
            https://ec.europa.eu/consumers/odr/
          </a>
        </p>
      </section>
    ),
    en: (
      <section>
        <h2>Credits</h2>

        <address>
          <strong>Hoteliers and Restaurant Owners Association</strong>
          <br />
          <strong>HGV Service Cooperative</strong>
          <br />
          Schlachthofstraße 59
          <br />
          I-39100 Bozen
        </address>

        <p>
          Tel. <a href="tel:+390471317700">+39 0471 317 700</a>
          <br />
          Fax +39 0471 317 701
          <br />
          <a href="mailto:info@bookingsuedtirol.com">
            info@bookingsuedtirol.com
          </a>
        </p>
        <p>VAT No. 00576540215</p>

        <h3>Copyright Information</h3>
        <h4>Icons</h4>
        <p>
          <a href="https://fonts.google.com/icons">Material Symbols</a>
        </p>

        <h3>Contents</h3>
        <p>
          This site contains initial information which is binding neither to the
          user nor to the HGV Service Cooperative. Binding information can be
          obtained from the individual providers. All contents of this site have
          been carefully checked and regularly up dated. Nevertheless errors can
          creep in, i.e. because of technical faults or wrong information added
          by unauthorised third parties. The HGV Service Cooperative accepts no
          responsibility for the correctness, completeness or that the
          information supplied by the relevant providers is up-to-date.
        </p>

        <h3>Copyright</h3>
        <p>
          All texts, images, pictures and other contents on this website are
          protected by copyright and may not be reproduced, saved, trasmitted or
          distributed in any way, in whole or in part, without prior consent.
        </p>

        <h4>
          Mandatory information according to the EU-Regulation N. 524/2013 of
          the European Parliament and of the Council
        </h4>
        <p>
          Platform for online dispute resolution for consumer disputes (ODR) to
          the European Commission:{" "}
          <a href="https://ec.europa.eu/consumers/odr/">
            https://ec.europa.eu/consumers/odr/
          </a>
        </p>
      </section>
    ),
  };

  return translations[language];
};
