import { translate } from "~/i18n";
import { APIProperty, Language } from "~/types";
import styles from "./contact.module.css";
import Container from "./container";
import Heading from "./heading";

interface Props {
  language: Language;
  property: APIProperty;
}

export default function Contact({
  language,
  property,
  property: { address }
}: Props) {
  const trans = translate(language);

  return (
    <section className={styles.contact}>
      <Container>
        <Heading level="h2" title={trans.contact} withSeparator={false} />
        <address className={styles.address}>
          {[
          property.name,
          address.street,
          `${address.postalCode ?? ""} ${address.municipality ?? ""}`.trim(),
          `${trans.southTyrol}/${trans.italy}`,
          property.phone ?
          <>
                Tel.{" "}
                <a href={`tel:${property.phone.replace(/\s+/g, "")}`}>
                  {property.phone}
                </a>
              </> :
          undefined,
          property.email ?
          <a href={`mailto:${property.email}`}>{property.email}</a> :
          undefined].

          filter(Boolean).
          map((children, index) =>
          <span key={index} className={styles.item}>
                {children}
              </span>
          )}
        </address>
      </Container>
    </section>);

}