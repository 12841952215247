import { json, LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { useEffect, useRef } from "react";
import wretch from "wretch";
import About from "~/components/about";
import Amenities from "~/components/amenities";
import BookingWidget from "~/components/bookingWidget";
import Contact from "~/components/contact";
import Container from "~/components/container";
import Footer from "~/components/footer";
import Heading from "~/components/heading";
import Hero from "~/components/hero";
import MapWrapper from "~/components/mapWrapper";
import Slider from "~/components/slider";
import { translate } from "~/i18n";
import { APIProperty, Language } from "~/types";
import { getHomeURLs, getLanguage, makeFetch } from "~/utils";

interface Data {
  language: Language;
  isBookingLinkCampaign: boolean;
  property: APIProperty;
  gtmId: string;
}

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  const gtmId = process.env["GTM_ID"];
  if (!gtmId) throw Error("Missing GTM_ID env variable");

  const apiOrigin = process.env["API_ORIGIN"];
  if (!apiOrigin) throw Error("Missing API_ORIGIN env variable");

  const propertyIdParam = params["propertyId"];
  const propertyId = Number(propertyIdParam);
  if (Number.isNaN(propertyId) || propertyId <= 0) {
    throw new Response("Not found", { status: 404 });
  }

  const url = new URL(request.url);

  const language = getLanguage(url);

  try {
    const property = await fetchAPIProperty({
      apiOrigin,
      language,
      propertyId,
    });

    return json<Data>({
      language,
      property,
      isBookingLinkCampaign:
        new URL(request.url).searchParams.get("utm_campaign") ===
        "booking_link",
      gtmId,
    });
  } catch (error) {
    if (error instanceof wretch.WretchError) {
      switch (error.status) {
        case 404:
          throw new Response("Not found", { status: 404 });
        case 401:
          // This happens if no Booking Widget exists for this property ID.
          throw new Response("Unauthorized", { status: 401 });
      }
    }

    throw error;
  }
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data) return [];

  const { property, language } = data;
  const trans = translate(language);
  return [
    { title: property.name },
    {
      name: "description",
      content: `${property.name}: ${
        trans.bookNextHolidayHere[property.guestInteraction]
      }`,
    },
  ];
};

export default function Page() {
  const { language, isBookingLinkCampaign, property, gtmId } =
    useLoaderData<typeof loader>();
  const trans = translate(language);

  const gtmRef = useRef<HTMLScriptElement>();

  useEffect(() => {
    // Google Tag Manager and Usercentrics must be disabled if it’s the "Google
    // Booking Link" campaign.
    if (isBookingLinkCampaign) return;

    // Add Google Tag Manager.
    if (!gtmRef.current) {
      gtmRef.current = document.createElement("script");
      gtmRef.current.textContent = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;
      document.head.appendChild(gtmRef.current);
    }
  }, []);

  return (
    <>
      {(property.seasonImages ?? property.galleryImages) && (
        <Hero images={property.seasonImages ?? property.galleryImages ?? []} />
      )}

      <main>
        <Container>
          <Heading
            level="h1"
            title={property.name}
            subtitle={trans.bookNextHolidayHere[property.guestInteraction]}
            withSeparator={true}
          />
        </Container>

        <div id="book">
          {property.isBookable ? (
            <BookingWidget language={language} propertyId={property.id} />
          ) : (
            <p>{trans.propertyNotBookable}</p>
          )}
        </div>

        <About language={language} property={property} />

        {property.seasonImages && property.galleryImages && (
          <Slider images={property.galleryImages} />
        )}

        {property.amenities && (
          <Amenities language={language} amenities={property.amenities} />
        )}

        {property.geolocation && (
          <MapWrapper
            propertyName={property.name}
            coordinates={{
              lat: property.geolocation.latitude,
              lng: property.geolocation.longitude,
            }}
          />
        )}

        <Contact language={language} property={property} />
      </main>

      <Footer
        language={language}
        property={property}
        alternateURLs={getHomeURLs(property.id)}
        isBookingLinkCampaign={isBookingLinkCampaign}
      />
    </>
  );
}

const fetchAPIProperty = ({
  apiOrigin,
  propertyId,
  language,
}: {
  apiOrigin: string;
  propertyId: number;
  language: Language;
}) =>
  makeFetch
    .url(`${apiOrigin}/widgets/v6/properties/${propertyId}`)
    .query({ lang: language, sourceId: 676 })
    .get()
    .json<APIProperty>();
